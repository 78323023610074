import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "../../utils/api.service";

export const getCountryList = createAsyncThunk("countryList", async (_, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    let { data: countryList } = await API.getCountryList();
    countryList = countryList?.map((item) => ({
      ...item,
      value: item.id,
      label: item.name,
    }));
    dispatch(setLoader(false));
    return countryList;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

const initialState = {
  isLoading: false,
  isModalOpen: {
    modalType: "",
    isOpen: false,
    data: null,
  },
  isSwlOpen: {
    modalType: "",
    data: null,
  },
  countryList: {
    data: [],
    error: null,
  },
};

const masterSlice = createSlice({
  name: "MASTER_SLICE",
  initialState,
  reducers: {
    setLoader: (state, action) => {
      state.isLoading = action.payload;
    },
    setModalStatus: (state, action) => {
      const { modalType,isOpen, data } = action.payload;
      state.isModalOpen.modalType = modalType;
      state.isModalOpen.isOpen = isOpen;
      state.isModalOpen.data = data;
    },
    setSwal: (state, action) => {
      const { modalType, data } = action.payload;
      state.isSwlOpen.modalType = modalType;
      state.isSwlOpen.data = data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCountryList.fulfilled, (state, action) => {
        state.countryList.data = action.payload;
      })
      .addCase(getCountryList.rejected, (state, action) => {
        state.countryList.error = action.error.message;
      });
  },
});

export const { setLoader, setModalStatus, setSwal, setCountryListByFirstLetter } = masterSlice.actions;
export default masterSlice.reducer;
