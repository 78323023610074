import React from "react";
import { Helmet } from "react-helmet";

function Footer() {
  return (
    <>
      <Helmet>
        <script>{`
                $(document).ready(function() {
                    let value = new Date().getFullYear();
                    document.getElementById("Footer_DATE").innerHTML = value;
                });
        `}</script>
        </Helmet>
            <footer className="footer m-3" style={{ "backgroundColor": "white", "padding": "15px", "paddingLeft": "0px", "color": "#000000cf" }}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            {/* <script>document.write(new Date().getFullYear())</script> */}
                            Copyright &copy; <label id='Footer_DATE'></label> Eduvels | All Rights Reserved
                        </div>
                    </div>
                </div>
            </footer>
    </>
  );
}

export default Footer;
