import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import Footer from "../Footer";

export const DashboardLayout = ({children }) => {
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  const location = useLocation();
  const path = '/' + location?.pathname?.split('/')?.[1]

  return (
    <>
      {path === '/login' || path === '/sign-up' || path === '/lock-screen' || path==='/complete-profile' || localStorage.getItem("AisLogin")===null || localStorage.getItem("AisLogin")===false ? <>
        {children}
        <Outlet />
      </>
        :
        <div id="wrapper">
          <Navbar/>
          <Sidebar />
          {children}
          <Outlet />
          <Footer />
        </div>
      }
    </>
  );
};
