import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "../../utils/api.service";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../utils/common.service";
import { setLoader } from "./masterSlice";

export const getSubjectList = createAsyncThunk("subjectList", async (pageChangeData, { dispatch }) => {
    try {
        dispatch(setLoader(true));
        console.log("pageChangeData", pageChangeData)
        let { data: subjectList, code, message } = await API.getSubjectList(pageChangeData);
        dispatch(setLoader(false));
        return { data: subjectList, code, message };
    } catch (error) {
        dispatch(setLoader(false));
        throw error;
    }
});

export const editSubject = createAsyncThunk("editSubject", async (editSubjectData, { dispatch }) => {
    try {
        dispatch(setLoader(true));
        console.log(editSubjectData)
        let { data: editSubject, code, message } = await API.editSubject(editSubjectData);
        dispatch(setLoader(false));
        if (code === '1') {
            TOAST_SUCCESS(message);
        } else {
            TOAST_ERROR(message)
        }
        return editSubject;
    } catch (error) {
        dispatch(setLoader(false));
        throw error;
    }
});

const initialState = {
    subjectList: {
        data: [],
        code: '',
        message: '',
        error: null,
    },
    editSubject: {
        data: [],
        error: null,
    },
};

const subjectSlice = createSlice({
    name: "SUBJECT",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSubjectList.fulfilled, (state, action) => {
                const { message, code, data } = action.payload;
                state.subjectList.data = data;
                state.subjectList.message = message;
                state.subjectList.code = code;
            })
            .addCase(getSubjectList.rejected, (state, action) => {
                state.subjectList.error = action.error.message;
            })
            .addCase(editSubject.fulfilled, (state, action) => {
                state.editSubject.data = action.payload;
            })
            .addCase(editSubject.rejected, (state, action) => {
                state.editSubject.error = action.error.message;
            });
    },
});

export default subjectSlice.reducer;
