import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as API from "../../utils/api.service";
import { ADMIN } from "../../app.config";
import { Helmet } from "react-helmet";
import logo from '../../image/logo-eduvels.png'
import { TOAST_ERROR, TOAST_SUCCESS } from "../../utils/common.service";
import { logOutRedirectCall } from "../../Pages/Common/RedirectPathMange";

const Navbar = () => {
    const navigate = useNavigate();

    const location = useLocation();
    const path = '/' + location?.pathname?.split('/')?.[1]

    const handleLogout = async () => {
        try {
            let res = await API.logout();
            if (res.code === '1') {
                TOAST_SUCCESS("Logout Successfully.")
                localStorage.clear();
                navigate('/login')
            } else {
                TOAST_ERROR("Something went wrong.")
            }
            
            // localStorage.removeItem(ADMIN);
            // navigate("/login");
        } catch (err) {
            console.log("err: ", err);
        }
    };

    return (
        <>
            {/* <!-- Topbar Start --> */}
            <div className="navbar-custom" style={{ "height": "50px", "backgroundColor": "#764AF1" }}>
                <div className="container-fluid">
                    <ul className="list-unstyled topnav-menu float-end mb-0" style={{ "height": "28px", "marginTop": "-10px" }}>

                        <li className="dropdown d-inline-block d-lg-none">
                            <a className="nav-link dropdown-toggle arrow-none waves-effect waves-light" data-bs-toggle="dropdown" href="/#" role="button" aria-haspopup="false" aria-expanded="false">
                                <i className="fe-search noti-icon"></i>
                            </a>
                            <div className="dropdown-menu dropdown-lg dropdown-menu-end p-0">
                                <form className="p-3">
                                    <input type="text" className="form-control" placeholder="Search ..." aria-label="Recipient's username" />
                                </form>
                            </div>
                        </li>

                        <li className="dropdown d-none d-lg-inline-block">
                            <a className="nav-link dropdown-toggle arrow-none waves-effect waves-light" data-toggle="fullscreen" href="/#">
                                <i className="fe-maximize noti-icon"></i>
                            </a>
                        </li>

                        <li className="dropdown notification-list topbar-dropdown">
                            <a className="nav-link dropdown-toggle nav-user me-0 waves-effect waves-light" href="/#" data-bs-toggle="dropdown" role="button" aria-haspopup="false" aria-expanded="false">
                                {/* <img src={JSON.parse(localStorage.getItem('MAuserData'))?.profile_image} alt="user_image" className="rounded-circle" /> */}
                                <span className="pro-user-name ms-1">
                                    Admin <i className="mdi mdi-chevron-down"></i>
                                </span>
                            </a>
                            <div className="dropdown-menu dropdown-menu-end profile-dropdown ">

                                <div className="dropdown-header noti-title">
                                    <h6 className="text-overflow m-0">Welcome !</h6>
                                </div>


                                {/* <Link to="/admin-profile" className={`dropdown-item notify-item ${path === "/admin-profile" ? "active" : ""}`}>
                                    <i className="fe-user"></i>
                                    <span>My Account</span>
                                </Link> */}


                                <Link to="/setting" className={`dropdown-item notify-item ${path === "/setting" ? "active" : ""}`}>
                                    <i className="fe-settings"></i>
                                    <span>Settings</span>
                                </Link>


                                {/* <Link onClick={()=>{lockScreenRedirectCall()}} className={`${props.SelectedTitle === "lock-screen" ? "dropdown-item notify-item active" : "dropdown-item notify-item"}`}>
                                        <i className="fe-lock"></i>
                                        <span>Lock Screen</span>
                                    </Link> */}

                                <div className="dropdown-divider"></div>


                                <a onClick={() => { handleLogout() }} className={`dropdown-item notify-item ${path === "" ? "active" : ""}`}>
                                    <i className="fe-log-out"></i>
                                    <span>Logout</span>
                                </a>

                            </div>
                        </li>


                    </ul>

                    <div className="logo-box" style={{ "height": "28px", "marginTop": "-10px" }}>
                        <Link to="/" className="logo logo-dark text-center">
                            <span className="logo-sm">
                                <img src="%PUBLIC_URL%/../assets/images/logo-sm.png" alt="user_image" height="22" />
                                {/* <!-- <span className="logo-lg-text-light">UBold</span> --> */}
                            </span>
                            <span className="logo-lg">
                                <img src="%PUBLIC_URL%/../assets/images/logo-dark.png" alt="user_image" height="20" />
                                {/* <!-- <span className="logo-lg-text-light">U</span> --> */}
                            </span>
                        </Link>

                        <Link to="/" className="logo logo-light text-center">
                            <span className="logo-sm">
                                <img src="%PUBLIC_URL%/../assets/images/logo-eduvels.png" alt="user_image" height="12" />
                            </span>
                            <span className="logo-lg">
                                <img src={logo} alt="logo" height="30" />
                            </span>
                        </Link>
                    </div>

                    <ul className="list-unstyled topnav-menu topnav-menu-left m-0">
                        <li>
                            <button className="button-menu-mobile waves-effect waves-light" style={{ "height": "50px", "marginTop": "-10px", "marginLeft": "-10px" }}>
                                <i className="fe-menu"></i>
                            </button>
                        </li>

                        <li>
                            {/* <!-- Mobile menu toggle (Horizontal Layout)--> */}
                            <a className="navbar-toggle nav-link" href="/#" data-bs-toggle="collapse" data-bs-target="#topnav-menu-content">
                                <div className="lines">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </a>
                        </li>


                    </ul>
                    <div className="clearfix"></div>
                </div>
            </div>
            <Helmet>
                <script src="../assets/js/app.min.js"></script>
            </Helmet>

        </>
    );
};

export default Navbar;
