import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "../../utils/api.service";
import { TOAST_SUCCESS } from "../../utils/common.service";
import { setLoader } from "./masterSlice";

export const getLanguageList = createAsyncThunk("languageList", async (pageChangeData, { dispatch }) => {
    try {
        dispatch(setLoader(true));
        let { data: languageList, code, message } = await API.getLanguageList(pageChangeData);
        dispatch(setLoader(false));
        return { data: languageList, code, message };
    } catch (error) {
        dispatch(setLoader(false));
        throw error;
    }
});

export const editLanguage = createAsyncThunk("editLanguage", async (editLanguageData, { dispatch }) => {
    try {
        dispatch(setLoader(true));
        let { data: editLanguage, code, message } = await API.editLanguage(editLanguageData);
        dispatch(setLoader(false));
        if (code === '1') {
            TOAST_SUCCESS(message);
        }
        return editLanguage;
    } catch (error) {
        dispatch(setLoader(false));
        throw error;
    }
});

const initialState = {
    languageList: {
        data: [],
        code: '',
        message: '',
        error: null,
    },
    editLanguage: {
        data: [],
        error: null,
    },
};

const languageSlice = createSlice({
    name: "SUBJECT",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getLanguageList.fulfilled, (state, action) => {
                const { message, code, data } = action.payload;
                state.languageList.data = data;
                state.languageList.message = message;
                state.languageList.code = code;
            })
            .addCase(getLanguageList.rejected, (state, action) => {
                state.languageList.error = action.error.message;
            })
            .addCase(editLanguage.fulfilled, (state, action) => {
                state.editLanguage.data = action.payload;
            })
            .addCase(editLanguage.rejected, (state, action) => {
                state.editLanguage.error = action.error.message;
            });
    },
});

export default languageSlice.reducer;
