import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "../../utils/api.service";
import { setLoader } from "./masterSlice";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../utils/common.service";

export const getSubscriptionList = createAsyncThunk("subscriptionList", async (pageChangeData, { dispatch }) => {
    try {
        dispatch(setLoader(true));
        let { data: subscriptionList, code, message } = await API.getSubscriptionList(pageChangeData);
        dispatch(setLoader(false));
        return { data: subscriptionList, code, message };
    } catch (error) {
        dispatch(setLoader(false));
        throw error;
    }
});

export const getSubscribedList = createAsyncThunk("subscribedList", async (pageChangeData, { dispatch }) => {
    try {
        dispatch(setLoader(true));
        let { data: subscribedList, code, message } = await API.getSubscribedList(pageChangeData);
        dispatch(setLoader(false));
        return { data: subscribedList, code, message };
    } catch (error) {
        dispatch(setLoader(false));
        throw error;
    }
});

export const editSubscription = createAsyncThunk("editSubscription", async (editSubscriptionData, { dispatch }) => {
    try {
        dispatch(setLoader(true));
        let { data: editSubscription, code, message } = await API.editSubscription(editSubscriptionData);
        dispatch(setLoader(false));
        if (code === '1') {
            TOAST_SUCCESS(message);
        } else {
            TOAST_ERROR(message)
        }
        return editSubscription;
    } catch (error) {
        dispatch(setLoader(false));
        throw error;
    }
});

const initialState = {
    subscriptionList: {
        data: [],
        code: '',
        message: '',
        error: null,
    },
    subscribedList: {
        data: [],
        code: '',
        message: '',
        error: null,
    },
    editSubscription: {
        data: [],
        error: null,
    },
};

const subscriptionSlice = createSlice({
    name: "SUBSCRIPTION",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSubscriptionList.fulfilled, (state, action) => {
                const { message, code, data } = action.payload;
                state.subscriptionList.data = data;
                state.subscriptionList.message = message;
                state.subscriptionList.code = code;
            })
            .addCase(getSubscriptionList.rejected, (state, action) => {
                state.subscriptionList.error = action.error.message;
            })
            .addCase(getSubscribedList.fulfilled, (state, action) => {
                const { message, code, data } = action.payload;
                state.subscribedList.data = data;
                state.subscribedList.message = message;
                state.subscribedList.code = code;
            })
            .addCase(getSubscribedList.rejected, (state, action) => {
                state.subscribedList.error = action.error.message;
            })
            .addCase(editSubscription.fulfilled, (state, action) => {
                state.editSubscription.data = action.payload;
            })
            .addCase(editSubscription.rejected, (state, action) => {
                state.editSubscription.error = action.error.message;
            });
    },
});

export default subscriptionSlice.reducer;
