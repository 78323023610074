import { configureStore } from "@reduxjs/toolkit";
import masterSlice from "./slices/masterSlice";
import subjectSlice from "./slices/subjectSlice";
import languageSlice from "./slices/languageSlice";
import subscriptionSlice from "./slices/subscriptionSlice";

const store = configureStore({
  reducer: {
    master: masterSlice,
    subject: subjectSlice,
    language: languageSlice,
    subscription: subscriptionSlice,
  },
});

export default store;
