import request from "./axios.service";
import { GET, POST, PUT, PATCH, DELETE, BASE_URL, ADMIN, AUTH, DASHBOARD, USER, TEACHER, COUNT_PER_PAGE, SUBJECT, LANGUAGE, SUBSCRIPTION, CMS, FAQ, CONTACT, NOTIFICATION } from "../app.config";

export const login = (body) => {
  return request(`/${AUTH}/login/`, POST, false, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const changePassword = (data) => {
  return request(`/${AUTH}/changePassword`, POST, true, data)
    .then((response) => {
      console.log("Response----------------", response);
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

// Dashboard Count
export const user_count = (data) => {
  return request(`/${DASHBOARD}/user_count`, GET, true, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

// Student List
export const studentList = (data) => {
  return request(`/${USER}/studentList`, POST, true, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

// edit student details
export const editUserDetails = (data) => {
  return request(`/${USER}/editUserDetails`, POST, true, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

// Delete student
export const deleteUser = (data) => {
  return request(`/${USER}/deleteUser`, POST, true, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

// Active and block student
export const BlockUnblockUser = (data) => {
  return request(`/${USER}/BlockUnblockUser`, POST, true, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

// Teacher List
export const teacherList = (data) => {
  return request(`/${USER}/teacherList`, POST, true, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};


export const logout = (data) => {
  return request(`${AUTH}/logout/`, POST, true, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};


export const getSubscriptionList = (data) => {
  return request(`${SUBSCRIPTION}/list-subscription`, POST, true, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const addSubscription = (data) => {
  return request(`${SUBSCRIPTION}/add-subscription`, POST, true, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getSubscribedList = (data) => {
  return request(`${SUBSCRIPTION}/list-subscribed`, POST, true, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const editSubscription = (data) => {
  return request(`${SUBSCRIPTION}/edit-subscription`, POST, true, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getSubjectList = (data) => {
  return request(`${SUBJECT}/list-subjects`, POST, true, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const editSubject = (data) => {
  return request(`${SUBJECT}/edit-subject`, POST, true, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteSubject = (data) => {
  return request(`${SUBJECT}/delete-subject`, POST, true, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const addSubject = (data) => {
  return request(`${SUBJECT}/add-subject`, POST, true, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getLanguageList = (data) => {
  return request(`${LANGUAGE}/list-languages`, POST, true, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const editLanguage = (data) => {
  return request(`${LANGUAGE}/edit-language`, POST, true, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteLanguage = (data) => {
  return request(`${LANGUAGE}/delete-language`, POST, true, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const addLanguage = (data) => {
  return request(`${LANGUAGE}/add-language`, POST, true, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};


// export const completeProfile = (data) => {
//   return request(`${BASE_URL}/${AUTH}/complete_profile`, POST, true, data)
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => {
//       throw error;
//     });
// };



// export const forgotPassword = (body) => {
//   return request(`${BASE_URL}/${AUTH}/forgot-password/`, POST, false, body)
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => {
//       throw error;
//     });
// };

// export const otpVerification = (body) => {
//   return request(`${BASE_URL}/${AUTH}/otp-verification/`, POST, false, body)
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => {
//       throw error;
//     });
// };

// export const resetPassword = (body) => {
//   return request(`${BASE_URL}/${AUTH}/reset-password/`, POST, false, body)
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => {
//       throw error;
//     });
// };

export const getCountryList = () => {
  return request(`${BASE_URL}/${AUTH}/countrylist`, POST, false, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const editAppContent = (body) => {
  return request(`${BASE_URL}/${CMS}/edit-content`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getAppContent = (body) => {
  return request(`${BASE_URL}/${CMS}/get-content`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getFAQs = (body) => {
  return request(`${BASE_URL}/${FAQ}/list-faqs`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const addFAQ = (body) => {
  return request(`${BASE_URL}/${FAQ}/add-faq`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const editFAQ = (body) => {
  return request(`${BASE_URL}/${FAQ}/edit-faq`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteFAQ = (body) => {
  return request(`${BASE_URL}/${FAQ}/delete-faq`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const activeInactiveFAQ = (body) => {
  return request(`${BASE_URL}/${FAQ}/active-inactive-faq`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const listTeacherContact = (body) => {
  return request(`${BASE_URL}/${CONTACT}/list-teacher-contact`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const resolveTeacherContact = (body) => {
  return request(`${BASE_URL}/${CONTACT}/resolve-teacher-contact`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const listStudentContact = (body) => {
  return request(`${BASE_URL}/${CONTACT}/list-student-contact`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const resolveStudentContact = (body) => {
  return request(`${BASE_URL}/${CONTACT}/resolve-student-contact`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getNotificationList = (body) => {
  return request(`${BASE_URL}/${NOTIFICATION}/list-notifications`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getAllUsersList = (body) => {
  return request(`${BASE_URL}/${NOTIFICATION}/get-all-users`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const addNotification = (body) => {
  return request(`${BASE_URL}/${NOTIFICATION}/add-notification`, POST, true, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};