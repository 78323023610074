
export function loginRedirectCall(){
    let path= window.location.protocol+"//"+window.location.host+"/login"
//    window.open(path,'mywindow').focus()
    window.location.href = path;
  }

export function homeRedirectCall(){
    let path= window.location.protocol+"//"+window.location.host+"/"
    window.location.href = path;
}

export function lockRedirectCall(){
  let path= window.location.protocol+"//"+window.location.host+"/lock-screen"
//    window.open(path,'mywindow').focus()
  window.location.href = path;
}

export function logOutRedirectCall(){
  console.log("object")
  localStorage.clear();
  loginRedirectCall()
}

export function lockScreenRedirectCall(){
  console.log("Lock Screen call")
  localStorage.removeItem("AisLogin");
  lockRedirectCall()
}

export function loginRedirectCallWithDataStore(data){
  console.log("Redirect Call --->>   ", data)
  localStorage.setItem("AisLogin",true);
  localStorage.setItem("Aid",data.id);
  localStorage.setItem("Aemail",data.email);
  localStorage.setItem("Atoken",data.token);
  homeRedirectCall()
}


export function confirmProfileRedirectCallWithDataStore(data){
  console.log("Confirm Profile Call-->>>    ",data)
  localStorage.setItem("PuserData",JSON.stringify(data));
  localStorage.setItem("Pid",data.id);
  localStorage.setItem("Atoken",data.token);
  localStorage.setItem("PUserType",data.user_type);
}