import axios from "axios";
import { DELETE, GET, PATCH, POST, PUT, API_KEY, ADMIN, OPTION, BASE_URL } from "../app.config";
import { logOutRedirectCall } from "../Pages/Common/RedirectPathMange";

/**
 * [request description]
 * @param  {[string]} url       URL of the API which needs to be consumed by client
 * @param  {[string]} method    Method type of the API call currently GET,POST,PUT,DELETE is supported in order suport to more methods add method name to the array -> allowedMethodTypes
 * @param  {[JSON]} payload     Payload to be provided to server for sending data
 * @param  {[string]} headers   Request Headers required by the server side to process the API call
 * @return {[JSON]}             Response provided by the server side code
 */

const request = async (url, method, token, payload, params={}) => {
  try {
    const allowedMethodTypes = [GET, POST, PUT, PATCH, DELETE, OPTION];

    if (allowedMethodTypes.indexOf(method.toLowerCase()) < 0) {
      throw new Error(`Invalid method type please provide one of these methods... \n ${allowedMethodTypes}`);
    }
    const headers = {
      "api-key": API_KEY,
      'Accept-language':'en',
      'Content-Type': 'application/json',
      ...(token && { token: localStorage.getItem('Atoken') }),
    };



    const result = await axios({ method, url, baseURL:BASE_URL ,params, data: payload, headers });

    return result;
  } catch (error) {
  console.log('error :', error);
  if (error.response.data.code === -1) {
    logOutRedirectCall();
  } else {
    throw new Error(error);
  }
  }
};

export default request;
